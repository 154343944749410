<template>
  <div v-if="dataPdf && schoolDetail" id="pdfDownloadContainer">
    <div id="headerDoc" class="reportCardHeader">
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <img :src="schoolDetail.about.path_landscape" width="100px" />
            </th>
            <th colspan="10">
              <div style="font-size: 20px">
                {{ schoolDetail.about.foundation }}
              </div>
              <div style="font-size: 22px">
                <b>{{ schoolDetail.about.name }}</b>
              </div>
              <div>{{ schoolDetail.about.address }}</div>
              <div>Telepon: {{ schoolDetail.about.phone }}</div>
              <div>Email: {{ schoolDetail.about.email }}</div>
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div style="margin: 20px 0; border-bottom: 1px solid black"></div>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Diri
          </th>
        </tr>
        <tr>
          <td colspan="4" rowspan="4">
            <div style="display: flex; justify-content: center; width: 100%">
              <img
                :src="dataPdf.siswa.data_diri.foto"
                height="180"
                width="auto"
              />
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Nama Lengkap</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.nama_lengkap }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Jenis Kelamin</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.jenis_kelamin }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Nama Panggilan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.nama_panggilan }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Agama</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.agama }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Tempat Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.tempat_lahir }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Kewarganegaraan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.kewarganegaraan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Tanggal Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.tanggal_lahir }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Bahasa Sehari-hari</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.bahasa }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Handphone</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.no_hp }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Email</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.email }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">NIK</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.nik }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Kartu Keluarga</div>
              <div class="text-pdf">{{ dataPdf.siswa.data_diri.no_kk }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Informasi Lainnya</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_diri.informasi_lainnya }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">NIS</div>
              <!-- <div class="text-pdf">{{ g_ppdbsiswa.student.nis }}</div> -->
              <div class="text-pdf">asdasd</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Alamat Siswa
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.alamat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Provinsi</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.provinsi }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kota / Kabupaten</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.kota }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kecamatan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.kecamatan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kelurahan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.kelurahan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RT</div>
              <div class="text-pdf">{{ dataPdf.siswa.alamat_siswa.rt }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RW</div>
              <div class="text-pdf">{{ dataPdf.siswa.alamat_siswa.rw }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kode Pos</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.kode_pos }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Telepon Rumah</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.telepon_rumah }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Tinggal Bersama</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.tinggal_bersama }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Keluarga
          </th>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Anak Ke</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.anak_ke }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Jumlah Saudara</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.alamat_siswa.jumlah_saudara }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="12"
            v-for="(d, i) in dataPdf.siswa.data_keluarga"
            :key="i"
          >
            <table style="padding: 10px">
              <tbody>
                <tr>
                  <td colspan="12">
                    Anak Ke <strong>{{ i + 1 }}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="12">
                    <div class="textWrapper">
                      <div class="title-pdf">Nama Anak</div>
                      <div class="text-pdf">{{ d.nama }}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <div class="textWrapper">
                      <div class="title-pdf">Sekolah / Bekerja</div>
                      <div class="text-pdf">{{ d.semester }}</div>
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="textWrapper">
                      <div class="title-pdf">
                        Nama Sekolah / Nama Perusahaan
                      </div>
                      <div class="text-pdf">{{ d.sekolah }}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Kesehatan
          </th>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Berat Badan (Kg)</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.berat }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tinggi Badan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.tinggi }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Golongan Darah</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.golongan_darah }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Riwayat Kesehatan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.riwayat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Jarak Rumah Ke Sekolah</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.jarak }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Catatan Kesehatan</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.data_kesehatan.catatan }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Pendidikan Sebelumnya
          </th>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Asal Sekolah Sebelumnya</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.asal }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Pokok Sekolah Nasional</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.no_pokok_sekolah }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat Sekolah</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.alamat_sekolah }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Induk Siswa Nasional</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.no_induk_sekolah }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Lulus Jenjang Sebelumnya</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.tahun_lulus }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">No. Ijazah (Jenjang Sebelumnya)</div>
              <div class="text-pdf">
                {{ dataPdf.siswa.pendidikan_sebelumnya.no_ijazah }}
              </div>
            </div>
          </td>
        </tr>
        <!-- <tr>
          <td colspan="12" v-for="(file, index) in dataPdf.siswa.pendidikan_sebelumnya
                    .dokumen"
                  :key="index">
            { File }
          </td>
        </tr> -->
      </tbody>
    </table>

    <div class="html2pdf__page-break"></div>
    <!-- Orang Tua Wali -->
    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Ayah
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Ayah</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.nama }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tempat Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.tempat_lahir }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tanggal Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.tanggal_lahir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kewarganegaraan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.kewarganegaraan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendidikan Terakhir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.pendidikan_terakhir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pekerjaan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.pekerjaan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendapatan Bulanan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.penghasilan_perbulan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Handphone</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.telepon }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Email</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.email }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Hubungan dengan Anak</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.hubungan_dengan_anak }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Agama</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.agama }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Informasi Lainnya</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ayah.informasi_lainnya }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Alamat Ayah
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.alamat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Provinsi</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.provinsi }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kota / Kabupaten</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.kota }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kecamatan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.kecamatan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kelurahan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.kelurahan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RT</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.rt }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RW</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.rw }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kode Pos</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.kode_pos }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Telepon Rumah</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ayah.telepon_rumah }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Ibu
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Ibu</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.nama }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tempat Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.tempat_lahir }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tanggal Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.tanggal_lahir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kewarganegaraan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.kewarganegaraan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendidikan Terakhir</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.pendidikan_terakhir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pekerjaan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.pekerjaan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendapatan Bulanan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.penghasilan_perbulan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Handphone</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.telepon }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Email</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.email }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Hubungan dengan Anak</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.hubungan_dengan_anak }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Agama</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.agama }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Informasi Lainnya</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.data_ibu.informasi_lainnya }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Alamat Ibu
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.alamat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Provinsi</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.provinsi }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kota / Kabupaten</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.kota }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kecamatan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.kecamatan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kelurahan</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.kelurahan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RT</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.rt }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RW</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.rw }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kode Pos</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.kode_pos }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Telepon Rumah</div>
              <div class="text-pdf">
                {{ dataPdf.orang_tua.alamat_ibu.telepon_rumah }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break"></div>
    <!-- Data Wali -->
    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Wali Murid
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Wali</div>
              <div class="text-pdf">{{ dataPdf.wali.data_wali.nama }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tempat Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.tempat_lahir }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tanggal Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.tanggal_lahir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kewarganegaraan</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.kewarganegaraan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendidikan Terakhir</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.pendidikan_terakhir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pekerjaan</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.pekerjaan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Pendapatan Bulanan</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.penghasilan_perbulan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Handphone</div>
              <div class="text-pdf">{{ dataPdf.wali.data_wali.telepon }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Email</div>
              <div class="text-pdf">{{ dataPdf.wali.data_wali.email }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Hubungan dengan Anak</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.hubungan_dengan_anak }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Agama</div>
              <div class="text-pdf">{{ dataPdf.wali.data_wali.agama }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Informasi Lainnya</div>
              <div class="text-pdf">
                {{ dataPdf.wali.data_wali.informasi_lainnya }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 10px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Alamat Wali
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.alamat }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Provinsi</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.provinsi }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kota / Kabupaten</div>
              <div class="text-pdf">{{ dataPdf.wali.alamat_wali.kota }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kecamatan</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.kecamatan }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kelurahan</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.kelurahan }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RT</div>
              <div class="text-pdf">{{ dataPdf.wali.alamat_wali.rt }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">RW</div>
              <div class="text-pdf">{{ dataPdf.wali.alamat_wali.rw }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kode Pos</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.kode_pos }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Telepon Rumah</div>
              <div class="text-pdf">
                {{ dataPdf.wali.alamat_wali.telepon_rumah }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    dataPdf: Object
  },
  computed: {
    schoolDetail() {
      return this.$store.getters.g_school;
    },
    g_ppdbsiswa() {
      return this.$store.getters.g_ppdbsiswa;
    }
  }
};
</script>

<style lang="scss">
#pdfDownloadContainer {
  @mixin centerElement {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  font-size: 12px;
  table,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 7px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  #headerDoc {
    table,
    th,
    td {
      border: none;
    }
  }

  .reportCardHeader {
    .reportCardLogo {
      font-size: 18px;
      font-weight: bold;
      @include centerElement;
    }
  }

  .reportCardFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .textWrapper {
    .title-pdf {
      font-size: 10px;
      font-weight: 500;
    }
    .text-pdf {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>
