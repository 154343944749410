<template>
  <div>
    <div>
      <v-row no-gutters justify="space-between" align="center">
        <div class="d-flex align-center">
          <FilterSchoolYear
            :withInitial="true"
            :isMaster="true"
            :dataFilter="dataFilter"
            :isHomeroom="isHomeroom"
            :tabHomeroom="tabHomeroom"
            @handler="getFilter"
          />
          <div class="subtitle-2 font-weight-bold ml-2">
            {{ total }} {{ $t("master_data.student.student") }}
          </div>
        </div>

        <v-text-field
          v-model="queryData.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          style="max-width: 250px"
        />
      </v-row>

      <v-divider class="my-3" />

      <v-row
        v-if="queryData.filter['class_students.school_year'].length > 0"
        class="mb-3"
        no-gutters
      >
        <v-btn
          :loading="loadingDownloadExcel"
          :disabled="dataStudent.length === 0"
          class="gradient-primary caption"
          dark
          depressed
        >
          <download-excel
            :fetch="downloadExcel"
            :fields="fields"
            :name="`${$t('master_data.student.student')} ${getNameFile()}.xls`"
          >
            <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
              $t("app.download")
            }}</span>
            <v-icon>mdi-cloud-download</v-icon>
          </download-excel>
        </v-btn>

        <v-spacer></v-spacer>

        <v-row v-if="multipleId.length > 0" justify="end" no-gutters>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                class="primary white--text mr-3"
                @click="setMutation(null)"
              >
                <v-icon>mdi-login-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.student.mutation") }}</span>
          </v-tooltip>
          <v-sheet v-if="gradeSelected.length == 1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="primary white--text mr-3"
                  @click="setAction('MOVE')"
                >
                  <v-icon>mdi-cursor-move</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.move_class") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="primary white--text mr-3"
                  @click="setAction('TO_NEXT')"
                >
                  <v-icon>mdi-chevron-double-up</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.upgrade") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="primary white--text"
                  @click="setAction('STAY')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("master_data.student.downgrade") }}</span>
            </v-tooltip>
          </v-sheet>
        </v-row>
      </v-row>
    </div>

    <v-sheet>
      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataStudent"
        :loading="loading"
        :options.sync="options"
        item-key="student"
        hide-default-footer
        show-select
        class="elevation-0 table-fixed mb-0"
        disable-pagination
      >
        <template v-slot:item.homeroom="{ item, value }">
          <v-row v-if="value.length > 1" no-gutters align="start">
            <span v-if="!item.viewMore">
              <span>{{ value[0].name }}</span>
            </span>
            <span v-else>
              <div v-for="ht in value" :key="ht.id" class="my-2">
                {{ ht.name }}
              </div>
            </span>

            <v-spacer></v-spacer>

            <v-btn
              small
              icon
              @click="item.viewMore = !item.viewMore"
              :class="item.viewMore ? 'mt-2' : ''"
            >
              <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="value.length == 1" no-gutters>{{ value[0].name }}</v-row>
        </template>
        <template v-slot:item.nis="{ item }">
          <v-edit-dialog
            :return-value.sync="item.nis"
            large
            persistent
            @save="saveNis(item.person, item.nis)"
          >
            <v-btn v-if="item.nis" color="primary" text>{{ item.nis }}</v-btn>
            <v-btn v-else color="warning" text>{{
              $t("master_data.student.input_nis")
            }}</v-btn>
            <template v-slot:input>
              <v-text-field
                v-model="item.nis"
                :rules="[v => !!v || $t('app.required')]"
                single-line
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.download="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                color="success"
                class="mr-1"
                icon
                v-on="on"
                @click="downloadPdfStudent(item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.download_student_detail") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu transition="slide-x-transition" bottom right rounded="lg">
            <template #activator="{ on, attrs }">
              <v-btn fab small depressed icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changePassword(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" color="primary">mdi-lock</v-icon>
                  {{ $t("master_data.student.change_password") }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="editStudent(item)">
                <v-list-item-title
                  ><v-icon class="mr-2" color="primary"
                    >mdi-square-edit-outline</v-icon
                  >
                  {{
                    $t("master_data.student.edit_student")
                  }}</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="
                  $router.push({
                    name: 'ProfilingStudent',
                    query: { person: item.person }
                  })
                "
              >
                <v-list-item-title
                  ><v-icon class="mr-2" color="primary">mdi-school</v-icon>
                  Student Profiling</v-list-item-title
                >
              </v-list-item>

              <!-- <v-list-item
                :disabled="multipleId.length > 0"
                @click="downloadPdf(item)"
              >
                <v-list-item-title
                  ><v-icon
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-file-pdf-box</v-icon
                  >
                  {{ $t("app.download") }}</v-list-item-title
                >
              </v-list-item> -->

              <v-list-item
                :disabled="multipleId.length > 0"
                @click="setMutation(item)"
              >
                <v-list-item-title
                  ><v-icon
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-login-variant</v-icon
                  >
                  {{ $t("master_data.student.mutation") }}</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                :disabled="multipleId.length > 0"
                @click="setAction('MOVE', item)"
              >
                <v-list-item-title
                  ><v-icon
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-cursor-move</v-icon
                  >
                  {{ $t("master_data.student.move_class") }}</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                :disabled="multipleId.length > 0"
                @click="setAction('TO_NEXT', item)"
              >
                <v-list-item-title>
                  <v-icon
                    v-if="item.grade != maxGrade"
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-chevron-double-up</v-icon
                  >
                  <v-icon
                    v-if="item.grade == maxGrade"
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-school</v-icon
                  >
                  {{
                    item.grade == maxGrade
                      ? $t("app.graduate")
                      : $t("master_data.student.upgrade")
                  }}</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                :disabled="multipleId.length > 0"
                @click="setAction('STAY', item)"
              >
                <v-list-item-title
                  ><v-icon
                    :disabled="multipleId.length > 0"
                    class="mr-2"
                    color="primary"
                    >mdi-chevron-double-down</v-icon
                  >
                  {{ $t("master_data.student.downgrade") }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <Pagination
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />
    </v-sheet>

    <v-dialog v-model="actionModal" persistent max-width="600">
      <v-card>
        <v-card-title class="title">
          {{ actionModalTitle }}
          <span v-if="!actionGraduate" class="ml-1">{{ textToYear }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!actionGraduate">
          <v-form ref="formAction" lazy-validation>
            <v-row class="mt-4">
              <v-col cols="12" sm="6">
                <v-select
                  v-model="actionGradeFrom"
                  :label="$t('master_data.student.actions.select_grade_from')"
                  :items="actionList.gradeList"
                  item-value="id"
                  item-text="grade"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  disabled
                  @change="getClassList('from')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="actionGradeTo"
                  :label="$t('master_data.student.actions.select_grade_to')"
                  :disabled="
                    formAction.action == 'STAY' ||
                      formAction.action == 'TO_NEXT'
                  "
                  :rules="[v => !!v || $t('app.required')]"
                  :items="actionList.gradeList"
                  item-value="id"
                  item-text="grade"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  @change="getClassList('to')"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="formAction.from_class"
                  :label="$t('master_data.student.actions.select_class_from')"
                  :items="actionList.classListFrom"
                  item-value="id"
                  item-text="name"
                  :item-disabled="item => item.id == formAction.to_class"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="formAction.to_class"
                  :disabled="
                    !actionGradeTo || actionList.classListTo.length == 0
                  "
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('master_data.student.actions.select_class_to')"
                  :items="actionList.classListTo"
                  item-value="id"
                  item-text="name"
                  :loading="actionLoading"
                  :item-disabled="item => item.id == formAction.from_class"
                  class="mb-3"
                  outlined
                  dense
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col v-if="actionGradeTo">
                <v-alert
                  v-if="actionList.classListTo.length == 0"
                  color="error"
                  text
                  dense
                  >{{ $t("master_data.student.no_class") }}</v-alert
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="
              () => {
                if (!actionGraduate) this.$refs.formAction.reset();
                actionModal = false;
              }
            "
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            v-if="actionGraduate"
            color="primary"
            depressed
            @click="saveAction('graduate')"
          >
            {{ $t("app.graduate") }}
          </v-btn>
          <v-btn v-else color="primary" depressed @click="saveAction()">
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddMutation
      type="student"
      :isOpen="modalMutation"
      :handler="type => closeMutation(type)"
      :data="selectedDataMutation"
    />

    <v-dialog v-model="changePasswordModal" width="500">
      <v-card>
        <v-form v-model="formPassword" ref="formPassword">
          <v-card-title>
            {{ $t("master_data.student.change_password") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-on:keyup.enter="saveChangePassword()"
              v-model="password.new_password"
              :label="$t('app.password_new')"
              :append-icon="newPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="newPass ? 'text' : 'password'"
              :rules="[v => !!v || $t('app.required')]"
              outlined
              dense
              @click:append="newPass = !newPass"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              text
              @click="changePasswordModal = false"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingChangePassword"
              @click="saveChangePassword()"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <vue-html2pdf
      ref="html2Pdf"
      :htmlToPdfOptions="pdfOptions()"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-content-width="97%"
      class="documentDownload"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <StudentDetailDownload :dataPdf="dataPdf" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import i18n from "@/i18n";
import FilterSchoolYear from "../../components/filterSchooYear";
import Pagination from "@/components/Pagination";
import AddMutation from "../components/AddMutation";
import StudentDetailDownload from "./StudentDetailDownload";
import VueHtml2pdf from "vue-html2pdf";

import { getMaxGrade, updatePasswordByAdmin } from "@/api/admin/admin";
import {
  studentsList,
  updateNIS,
  saveAction
} from "@/api/admin/master/student";

import { downloadPersonDetail } from "@/api/admin/master";

import {
  get_school_year_list,
  get_grade_list,
  get_class_list
} from "@/api/admin/schoolClass";
import { getApplicantByID } from "@/api/admin/master/applicant";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Pagination,
    FilterSchoolYear,
    AddMutation,
    StudentDetailDownload,
    VueHtml2pdf
  },
  props: {
    isHomeroom: Boolean,
    tabHomeroom: Number
  },
  data() {
    return {
      modalMutation: false,
      selectedDataMutation: [],
      checkAll: false,
      multipleId: [],
      multiple: false,
      singleId: [],
      dataStudent: [],
      loading: false,
      options: {},
      actionModal: false,
      actionModalTitle: "",
      actionType: "",
      actionLoading: false,
      actionGradeFrom: null,
      actionGradeTo: null,
      actionNearWillCome: null,
      actionGraduate: false,
      gradeSelected: [],
      loadingDownloadExcel: false,
      next_school_year: null,
      fields: {
        [i18n.t("master_data.table.name")]: "name",
        [i18n.t("app.username")]: {
          field: "username",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.table.nis")]: {
          field: "nis",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("app.grade")]: "grade",
        [i18n.t("app.class_name")]: "class_name",
        [i18n.t("master_data.table.phone")]: {
          field: "phone",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.table.guardian")]: {
          field: "homeroom",
          callback: value => value.map(v => v.name)
        },
        [i18n.t("master_data.table.uid")]: "uid",
        [i18n.t("app.nis")]: {
          field: "nis",
          callback: value => this.preprocessLongNum(value)
        }
      },
      actionList: {
        schoolYearList: [],
        gradeList: [],
        classListFrom: [],
        classListTo: []
      },
      formAction: {
        students: [],
        from_class: null,
        to_class: null,
        year: null,
        action: ""
      },
      tableHeaders: [
        {
          text: i18n.t("master_data.table.name"),
          align: "left",
          value: "name"
        },
        {
          text: i18n.t("app.username"),
          value: "username"
        },
        {
          text: i18n.t("master_data.table.nis"),
          value: "nis"
        },
        {
          text: i18n.t("app.grade"),
          value: "grade"
        },
        {
          text: i18n.t("app.class_name"),
          value: "class_name"
        },
        {
          text: i18n.t("master_data.table.phone"),
          value: "phone"
        },
        {
          text: i18n.t("master_data.table.guardian"),
          value: "homeroom",
          width: 150
        },
        {
          text: i18n.t("master_data.table.uid"),
          value: "uid"
        },
        {
          text: i18n.t("app.download"),
          value: "download",
          sortable: false
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action"
        }
      ],
      nameClassSelected: "",
      nameGradeSelected: "",
      nameSchoolYearSelected: "",
      total: 0,
      length: 0,
      queryData: {
        page: 1,
        limit: 10,
        sort: ["grade", "class_name", "nis"],
        order: "ASC",
        search: "",
        institution: "",
        filter: {
          "class_students.class": [],
          "class_masters.id": [],
          "class_students.school_year": []
        }
      },
      dataFilter: {},
      password: {
        person: null,
        new_password: ""
      },
      loadingChangePassword: false,
      newPass: true,
      changePasswordModal: false,
      formPassword: false,
      dataPdf: null,
      maxGrade: null,
      selectedDownloadPdf: null
    };
  },
  computed: {
    textToYear() {
      if (this.next_school_year) {
        let year = this.next_school_year;
        let title = "";
        switch (this.actionType) {
          case "STAY":
            title = `${i18n.t("app.at")} ${year.start_year} / ${year.end_year}`;
            break;
          case "TO_NEXT":
            title = `${i18n.t("app.to")} ${year.start_year} / ${year.end_year}`;
            break;
          default:
            // STAY
            title = "";
            break;
        }
        return title;
      } else return "";
    }
  },
  mounted() {
    if (this.isHomeroom) {
      if (this.tabHomeroom == 0) this.queryData.type = "homeroom";
      else this.queryData.type = "teacher";
    }
    this.getData();
    getMaxGrade().then(res => (this.maxGrade = res.data.data.max_grade));
    this.dataFilter = this.$route.query;
  },
  watch: {
    "$route.query.tab"(newVal) {
      if (newVal == 0) {
        this.getData();
      }
    },
    "queryData.search"() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getData();
      }, doneTypingInterval);
    },
    tabHomeroom(newVal) {
      if (newVal == 0) this.queryData.type = "homeroom";
      else this.queryData.type = "teacher";

      this.getData();
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.loading = true;
          this.queryData.sort = [param.sortBy[0]];
          this.queryData.page = param.page;
          this.queryData.limit = param.itemsPerPage;
          this.getData();
        }
      }
    }
  },
  methods: {
    preprocessLongNum(value) {
      this.countDownload = this.countDownload + 1;
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        // if (value > 99999999999 || value < 0.0000000000001) {
        return `="${value}"`;
        // }
      }
      return value;
    },
    async downloadPdfStudent(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      downloadPersonDetail({ person: item.person })
        .then(res => {
          if (res.data.code) {
            window.open(res.data.data.path);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.$store.commit("TOGGLE_FULL_LOADING");
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    onProgress(e) {
      if (e == 100) this.$store.commit("TOGGLE_FULL_LOADING");
    },
    async downloadPdf(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      this.selectedDownloadPdf = item;
      const detailReport = await getApplicantByID({ id: item.id });
      this.dataPdf = detailReport.data.data;

      setTimeout(async () => {
        await this.$refs.html2Pdf.generatePdf();
        this.selectedDownloadPdf = null;
        this.dataPdf = null;
      }, 100);
    },
    pdfOptions() {
      const filename = this.selectedDownloadPdf
        ? `${this.selectedDownloadPdf.name}-${this.selectedDownloadPdf.nis}.pdf`
        : "";

      return {
        margin: 0.3,
        filename: filename,
        image: {
          type: "jpeg",
          quality: 0.98
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          bottom: 20,
          useCORS: true
        },
        jsPDF: {
          unit: "cm",
          format: "a4",
          orientation: "portrait"
        }
      };
    },
    saveChangePassword() {
      if (this.$refs.formPassword.validate()) {
        this.loadingChangePassword = true;
        updatePasswordByAdmin(this.password)
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("app.change_password_success"),
                color: "success"
              });
              this.changePasswordModal = false;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingChangePassword = false;
          })
          .catch(() => (this.loadingChangePassword = false));
      }
    },
    changePassword(item) {
      this.password = {
        person: null,
        new_password: ""
      };
      this.$nextTick(() => {
        this.changePasswordModal = true;
        this.password.person = item.person;
      });
    },
    editStudent(item) {
      const queryParam = {
        id: item.student,
        year: this.queryData.filter["class_students.school_year"][0],
        grade:
          this.queryData.filter["class_masters.id"].length > 0
            ? this.queryData.filter["class_masters.id"].join("+")
            : [],
        class:
          this.queryData.filter["class_students.class"].length > 0
            ? this.queryData.filter["class_students.class"].join("+")
            : []
      };

      this.$router.push({
        path: "student-manual",
        query: queryParam
      });
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await studentsList(query);
      this.loadingDownloadExcel = false;
      if (res.data.code) {
        if (res.data.data.data.length == 0) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: i18n.t("app.data_not_found"),
            color: "error"
          });
        } else return res.data.data.data;
      }
    },
    getApiClassList(param, queryData) {
      this.actionLoading = true;
      get_class_list(queryData, true).then(res => {
        if (res.data.code) {
          if (param == "from") {
            this.actionList.classListFrom = res.data.data;
          } else {
            this.actionList.classListTo = res.data.data;
          }
        }
        this.actionLoading = false;
      });
    },
    getClassList(param) {
      let setSchoolYear = "";
      let setGrade = param == "to" ? this.actionGradeTo : this.actionGradeFrom;

      if (this.next_school_year) {
        if (this.actionType == "MOVE") setSchoolYear = this.formAction.year;
        else {
          setSchoolYear =
            param == "from" ? this.formAction.year : this.next_school_year.id;
        }
      } else {
        setSchoolYear = this.formAction.year;
      }

      let queryData = {
        school_year: [setSchoolYear],
        grade: [setGrade]
      };

      if (this.actionType == "MOVE") {
        this.actionModal = true;
        this.getApiClassList(param, queryData);
      } else {
        if (this.next_school_year) {
          this.actionModal = true;
          this.getApiClassList(param, queryData);
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: i18n.t("master_data.student.next_year_required"),
            color: "warning"
          });
        }
      }
    },
    async setAction(type, data) {
      this.actionType = null;
      this.loading = true;
      this.actionNearWillCome = null;
      this.formAction.to_class = null;
      this.formAction.from_class = null;
      this.actionGradeTo = null;
      this.actionGradeFrom = null;
      this.actionGraduate = false;
      this.actionType = type;
      let getGradeId = null;
      let getClassId = null;
      let getDataStudent = [];
      let getGrade = null;

      switch (type) {
        case "MOVE":
          this.actionModalTitle = i18n.t("master_data.student.move_class");
          break;
        case "STAY":
          this.actionModalTitle = i18n.t("master_data.student.downgrade");
          break;
        default:
          this.actionModalTitle = i18n.t("master_data.student.upgrade");
          break;
      }

      if (data == undefined) {
        if (this.multipleId.length > 0) {
          getGradeId = this.multipleId[0].grade_id;
          getDataStudent = this.multipleId.map(r => r.student);
          getClassId = this.multipleId[0].class;
          getGrade = this.multipleId[0].grade;
        }
      } else {
        getGradeId = data.grade_id;
        getDataStudent = [data.student];
        getGrade = data.grade;
        getClassId = data.class;
      }

      const getGradeList = await get_grade_list();
      const gradeList = getGradeList.data.data;

      if (getGradeList.data.code) {
        this.actionList.gradeList = gradeList;
        this.actionGradeFrom = getGradeId;
        await this.getClassList("from");
      }

      if (type == "STAY") {
        this.actionGradeTo = getGradeId;
        this.getClassList("to");
      } else if (type == "TO_NEXT") {
        const getNextGradeIdx = gradeList
          .map(r => r.id)
          .indexOf(this.actionGradeFrom);
        const schoolYear = await get_school_year_list(true);
        const schoolYearData = schoolYear.data.data;

        if (getGrade == this.maxGrade) {
          this.actionGraduate = true;
          this.actionModalTitle = i18n.t("master_data.student.graduate");
        } else {
          this.actionGradeTo = gradeList[getNextGradeIdx + 1].id;
        }

        if (this.next_school_year) {
          const getYear = schoolYearData.find(
            r => r.sequence == this.next_school_year.sequence
          );

          this.actionNearWillCome = getYear;
          this.getClassList("to");
        }
      }

      this.formAction.students = getDataStudent;
      this.formAction.from_class = getClassId;
      this.formAction.action = type;
      this.loading = false;
    },
    saveAction(type) {
      if (type == "graduate" || this.$refs.formAction.validate()) {
        let data = { ...this.formAction };
        data.year = data.year.length > 0 ? data.year[0] : data.year;
        if (type == "graduate") data.action = "GRADUATE";
        if (data.action == "TO_NEXT") data.year = this.actionNearWillCome.id;

        saveAction(data).then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: i18n.t("master_data.student.response.success_action"),
              color: "success"
            });

            this.getData();
            this.actionModal = false;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
        });
      }
    },
    closeMutation(type) {
      if (type == "save") this.getData();
      this.modalMutation = false;
      this.multipleId = [];
    },
    setMutation(data) {
      if (!data) {
        this.selectedDataMutation = this.multipleId;
        this.modalMutation = true;
      } else {
        this.selectedDataMutation = [{ ...data }];
        this.modalMutation = true;
      }
    },
    getFilter(res) {
      this.multipleId = [];

      if (res.type == "school_year") {
        this.next_school_year = null;
        let getSchoolYear = null;

        getSchoolYear = res.master.schoolYearList.find(
          r => r.sequence == res.data.sequence + 1
        );
        if (getSchoolYear) this.next_school_year = getSchoolYear;
      }

      this.queryData.page = 1;
      this.queryData.filter["class_students.school_year"] =
        res.filter.school_year;
      this.formAction.year = res.filter.school_year;
      this.queryData.filter["class_masters.id"] = res.filter.grade;
      this.gradeSelected = res.filter.grade;
      this.queryData.filter["class_students.class"] = res.filter.class;
      if (res.type === "school_year") {
        this.nameSchoolYearSelected = res.selected
          .map(item => `${item.start_year} - ${item.end_year}`)
          .join(", ");
        this.nameSchoolYearSelected = `${i18n.t("app.school_year")} ${
          this.nameSchoolYearSelected
        }`;
      }
      if (res.type === "select_grade") {
        this.nameGradeSelected = res.selected
          .map(item => item.grade)
          .join(", ");
        this.nameGradeSelected = `${i18n.t("app.grade")} ${
          this.nameGradeSelected
        }`;
      }
      if (res.type === "select_class") {
        this.nameClassSelected = res.selected.map(item => item.name).join(", ");
        this.nameClassSelected = `${i18n.t("app.class")} ${
          this.nameClassSelected
        }`;
      }
      this.getData();
    },
    getNameFile() {
      if (this.nameClassSelected) {
        return this.nameClassSelected;
      } else if (this.nameGradeSelected) {
        return this.nameGradeSelected;
      } else {
        return this.nameSchoolYearSelected;
      }
    },
    concatString: item => item.grade + " / " + item.name,
    getData() {
      this.dataStudent = [];
      if (this.formAction.year) {
        this.loading = true;
        const newData = [];
        studentsList(this.queryData)
          .then(res => {
            this.loading = false;
            const r = res.data.data;
            this.total = r.total;
            this.length = r.last_page;
            r.data.map(d => newData.push({ ...d, viewMore: false }));
            this.dataStudent = newData;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    async saveNis(idStudent, idNis) {
      updateNIS({ person: idStudent, nis: idNis })
        .then(res => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: res.data.code ? "success" : "error"
          });
          this.getData();
          this.loading = false;
        })
        .catch(err => {
          if (err) this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select__slot {
  .v-label {
    font-size: 14px;
  }
}
</style>
